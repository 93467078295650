<!-- head -->
<div class="partners-head">
    <div class="ph-wrap">
        <div class="ph-subwrap">
            <div class="ph-content">
                <h1>{{words['partners']?.['head-title']?.[lang.slug]}}</h1>
                <div class="ph-list">
                  <div class="ph-item" [innerHTML]="words['partners']?.['text1']?.[lang.slug]"></div>
                  <div class="ph-item">{{words['partners']?.['text2']?.[lang.slug]}}</div>
                  <div class="ph-item">{{words['partners']?.['text3']?.[lang.slug]}}</div>
                </div>
                <a class="btn ph-btn" (click)="onPromoBtnClick()"><span>{{words['home']?.['head-btn']?.[lang.slug]}}</span></a>
            </div>

            <div class="ph-logos">
                <div class="phl-container" id="binance">
                    <img src="/assets/images/partners_binance.svg" />
                </div>
                <div class="phl-container" id="euroclear">
                    <img src="/assets/images/partners_euroclear.svg" />
                </div>
                <div class="phl-container" id="bybit">
                    <img src="/assets/images/partners_bybit.svg" />
                </div>
                <div class="phl-container" id="coinbase">
                    <img src="/assets/images/partners_coinbase.svg" />
                </div>
                <div class="phl-container" id="kraken">
                    <img src="/assets/images/partners_kraken.svg" />
                </div>
                <div class="phl-container" id="trust">
                    <img src="/assets/images/partners_trust.svg" />
                </div>
            </div>
        </div>
    </div>
</div>
