import { Directive, Input } from "@angular/core";
import { AppService } from "src/app/common/services/app.service";
import { AuthService } from "src/app/common/services/auth.service";
import { ICard } from "src/app/model/entities/card.interface";
import { IUser } from "src/app/model/entities/user.interface";
import { PopupComponent } from "../popup.component";

@Directive()
export abstract class PopupReceiveComponent extends PopupComponent {
    @Input() card: ICard;

    constructor(
        protected appService: AppService,
        protected authService: AuthService,
    )
    {
        super(appService);
    }

    get user(): IUser {return this.authService.user;}

    public copy(txt: string): void {
        window.navigator.clipboard.writeText(txt);
    }
}
