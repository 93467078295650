<div #win class="cs-win" [style.height]="height+'px'">
    <div
        #container
        class="cs-container"
        (mousedown)="onDragStart($event);"
        (touchstart)="onDragStart($event);"
    >
        <div
            *ngFor="let card of cards; let cardIndex=index;"
            class="cs-item"
            [style.min-width]="cardIndex === step ? width+'px' : width * 1/(cardIndex/10 + 1) + 'px'"
            [style.width]="cardIndex === step ? width+'px' : width / (cardIndex + 1) + 'px'"
            [style.bottom]="cardIndex !== step ? -(cardIndex + 1) * 25 + 'px' : 0"
            [style.z-index]="cardIndex === step ? 666 : 10 - (cardIndex + 1)"
            [style.visibility]="cardIndex < step - 2 || cardIndex > step + 2 ? 'hidden' : 'visible'"
            [class.cs-inactive-item]="cardIndex !== step"
        >
          <the-card customClass="card-class" [card]="card"></the-card>
        </div>
    </div>
    <div class="cs-controls" *ngIf="cards.length > 1">
        <button (click)="onPrev()" class="smallbtn left"><span></span></button>
        <button (click)="onNext()" class="smallbtn right"><span></span></button>
    </div>
</div>
