<header [class.opaque]="opaque || mmMainActive || mmAccountActive">
    <div class="h-content-desktop">
        <div class="h-logolangs">
            <div class="h-logo"><a routerLink="/{{lang.slug}}"><img src="/assets/images/logo.svg" alt="logo"></a></div>
            <lang-dropdown></lang-dropdown>
        </div>
        <nav class="h-menu">
            <ul>
                <li><a [class.active]="!url[2]" routerLink="/{{lang.slug}}">{{words['header']?.['main']?.[lang.slug]}}</a></li>
                <li><a [class.active]="url[2] === 'about' && !url[3]" routerLink="/{{lang.slug}}/about">{{words['header']?.['about']?.[lang.slug]}}</a></li>
                <li><a [class.active]="url[2] === 'partners' && !url[3]" routerLink="/{{lang.slug}}/partners">{{words['header']?.['partners']?.[lang.slug]}}</a></li>
                <li><a [class.active]="url[2] === 'services' && !url[3]" routerLink="/{{lang.slug}}/services">{{words['header']?.['services']?.[lang.slug]}}</a></li>
                <li><a [class.active]="url[2] === 'deposit' && !url[3]" routerLink="/{{lang.slug}}/deposit">{{words['header']?.['deposit']?.[lang.slug]}}</a></li>
                <li><a [class.active]="url[2] === 'crypto' && !url[3]" routerLink="/{{lang.slug}}/crypto">{{words['header']?.['crypto-transfer']?.[lang.slug]}}</a></li>
            </ul>
        </nav>
        <div class="h-account">
            <div class="ha-links">
                <a [href]="words['header']?.['support-link']?.[lang.slug]" target="_blank" class="btn ha-btn-support">
                    <span></span>
                </a>
            </div>
            <div class="h-border"></div>
            <ng-container *ngIf="authenticated">
                <div class="ha-notifications">
                    <div class="btn ha-btn-notifications" (click)="pNotificationsActive = true"><span></span></div>
                    <panel-notifications [(active)]="pNotificationsActive"></panel-notifications>
                </div>
                <div class="ha-links">
                    <a class="btn ha-btn-account" routerLink="/{{lang.slug}}/account" [class.active]="url[2] === 'account' && !url[3]">
                      <span></span>
                    </a>
                    <a class="btn ha-btn-logout" (click)="pLogoutActive = true"><span></span></a>
                    <panel-logout [(active)]="pLogoutActive"></panel-logout>
                </div>
            </ng-container>
            <ng-container *ngIf="!authenticated">
                <div class="ha-links">
                    <a (click)="pLoginActive = true" class="btn ha-btn">
                        <span class="ha-btn-text">{{words['header']?.['login']?.[lang.slug]}}</span>
                        <span class="ha-btn-no-text"></span>
                    </a>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="h-content-mobile">
        <div class="smallbtn" [class.menu]="!mmMainActive" [class.close]="mmMainActive" (click)="toggleMMMain()"></div>
        <div class="h-logo"><a routerLink="/{{lang.slug}}"><img src="/assets/images/logo.svg" alt="logo"></a></div>
        <ng-container *ngIf="authenticated">
            <div class="ha-links-mob">
                <div class="ha-notifications">
                    <a class="btn ha-btn ha-btn-notifications" routerLink="/{{lang.slug}}/account/notifications"><span></span></a>
                    <panel-notifications [(active)]="pNotificationsActive"></panel-notifications>
                </div>
                <div class="ha-links">
                    <a class="btn ha-btn ha-btn-account" routerLink="/{{lang.slug}}/account" [class.active]="url[2] === 'account' && !url[3]">
                      <span></span>
                    </a>
                    <a class="btn ha-btn ha-btn-logout" (click)="pLogoutActive = true"><span></span></a>
                    <!-- <panel-logout [(active)]="pLogoutActive"></panel-logout> -->
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="!authenticated">
            <a (click)="pLoginActive = true" class="btn ha-btn">
                <span class="ha-btn-text">{{words['header']?.['login']?.[lang.slug]}}</span>
                <span class="ha-btn-no-text"></span>
            </a>
        </ng-container>
    </div>
    <mm-main [(active)]="mmMainActive" (menuClick)="onMenuClick($event)"></mm-main>
    <mm-account [(active)]="mmAccountActive"></mm-account>
</header>
