<div class="popup-wrap" [class.active]="active" (click)="onClick($event)">
    <div class="popup" id="popup-{{unique}}">
        <div class="popup-head"><div class="smallbtn close" (click)="onClose()"></div></div>
        <div class="popup-title">{{words['register']?.['title']?.[lang.slug]}}</div>
        <div class="popup-content compact">
            <form class="gf" (submit)="onSubmit()">
                <div class="gf-row b-margined-20">
                    <div class="gf-inputwrap"><input type="text" name="lastname" [(ngModel)]="lastname" (keyup)="removeNonLatin('lastname')" [class.error]="errors['lastname']" [placeholder]="words['register']?.['lastname']?.[lang.slug]"></div>
                    <div *ngIf="errors['lastname']" class="gf-error">{{words['errors']?.[errors['lastname']]?.[lang.slug]}}</div>
                </div>
                <div class="gf-row b-margined-20">
                    <div class="gf-inputwrap"><input type="text" name="firstname" [(ngModel)]="firstname" (keyup)="removeNonLatin('firstname')" [class.error]="errors['firstname']"  [placeholder]="words['register']?.['firstname']?.[lang.slug]"></div>
                    <div *ngIf="errors['firstname']" class="gf-error">{{words['errors']?.[errors['firstname']]?.[lang.slug]}}</div>
                </div>
                <div class="gf-row b-margined-20">
                    <div class="gf-inputwrap"><input type="text" name="middlename" [(ngModel)]="middlename" (keyup)="removeNonLatin('middlename')" [class.error]="errors['middlename']" [placeholder]="words['register']?.['middlename']?.[lang.slug]"></div>
                    <div *ngIf="errors['middlename']" class="gf-error">{{words['errors']?.[errors['middlename']]?.[lang.slug]}}</div>
                </div>
                <div class="gf-row b-margined-20">
                    <div class="gf-inputwrap"><input type="text" name="phone" [(ngModel)]="phone" (keyup)="removeNonDigit('phone')" [class.error]="errors['phone']" [placeholder]="words['register']?.['phone']?.[lang.slug]"></div>
                    <div *ngIf="errors['phone']" class="gf-error">{{words['errors']?.[errors['phone']]?.[lang.slug]}}</div>
                </div>
                <div class="gf-tabs">
                    <div class="gf-row b-margined-20">
                        <div class="gf-inputwrap"><input type="email" name="email" [(ngModel)]="email" [class.error]="errors['email']" [placeholder]="words['register']?.['email']?.[lang.slug]" [disabled]="!isPhone"></div>
                        <div *ngIf="errors['email']" class="gf-error">{{words['errors']?.[errors['email']]?.[lang.slug]}}</div>
                    </div>
                    <input-switch [(value)]="isPhone"></input-switch>
                </div>
                <div class="gf-row b-margined-20">
                    <div class="gf-inputwrap"><input type="text" name="addr" [(ngModel)]="addr" [class.error]="errors['addr']" [placeholder]="words['register']?.['addr']?.[lang.slug]"></div>
                    <div *ngIf="errors['addr']" class="gf-error">{{words['errors']?.[errors['addr']]?.[lang.slug]}}</div>
                </div>
                <div class="gf-row b-margined-20">
                    <div class="gf-inputwrap"><input type="password" name="password" [(ngModel)]="password" [class.error]="errors['password']" [placeholder]="words['register']?.['password']?.[lang.slug]"></div>
                    <div *ngIf="errors['password']" class="gf-error">{{words['errors']?.[errors['password']]?.[lang.slug]}}</div>
                </div>
                <div class="gf-row b-margined-30">
                    <div class="gf-inputwrap"><input type="password" name="password2" [(ngModel)]="password2" [class.error]="errors['password2']" [placeholder]="words['register']?.['password2']?.[lang.slug]"></div>
                    <div *ngIf="errors['password2']" class="gf-error">{{words['errors']?.[errors['password2']]?.[lang.slug]}}</div>
                </div>
                <div class="gf-row b-margined-30">
                    <div class="gf-btnwrap"><button type="submit" class="btn" [class.busy]="loading"><span>{{words['register']?.['register']?.[lang.slug]}}</span></button></div>
                    <div *ngIf="errorExists" class="gf-error ta-center">{{words['errors']?.['email-exists']?.[lang.slug]}}</div>
                </div>
                <div class="gf-row">
                    <div class="gf-note ta-center">{{words['register']?.['already']?.[lang.slug]}} <strong><a (click)="login()">{{words['register']?.['login']?.[lang.slug]}}</a></strong></div>
                </div>
            </form>
        </div>
    </div>
</div>
