import { Component } from "@angular/core";
import { MMComponent } from "../mm.component";

@Component({
    selector: "mm-account",
    templateUrl: "mm-account.component.html",
    styleUrls: [
        "../mm.component.scss",
        "mm-account.component.scss",
    ]
})
export class MMAccountComponent extends MMComponent {
    set pRegisterActive(v: boolean) {this.appService.pRegisterActive = v;}
    set pLoginActive(v: boolean) {this.appService.pLoginActive = v;}
    set pLogoutActive(v: boolean) {this.appService.pLogoutActive = v;}

    public activatePopupRegister(): void {
        this.pRegisterActive = true;
        this.close();
    }

    public activatePopupLogin(): void {
        this.pLoginActive = true;
        this.close();
    }

    public activatePopupLogout(): void {
        this.pLogoutActive = true;
        this.close();
    }
}
