import { Component, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs';
import { AppService } from './common/services/app.service';
import { AuthService } from './common/services/auth.service';
import { LangRepository } from './common/services/repositories/lang.repository';
import { SettingRepository } from './common/services/repositories/setting.repository';
import { UfileRepository } from './common/services/repositories/ufile.repository';
import { WordRepository } from './common/services/repositories/word.repository';
import { ISettings } from './model/entities/settings.interface';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
    @ViewChild("win", {static: false}) private winRef: ElementRef;
    private settingsReady: boolean = false;
	  private langsReady: boolean = false;
	  private wordsReady: boolean = false;
	  private ufilesReady: boolean = false;

	  constructor(
		    private appService: AppService,
        private authService: AuthService,
		    private langRepository: LangRepository,
		    private settingRepository: SettingRepository,
		    private wordRepository: WordRepository,
		    private ufileRepository: UfileRepository,
		    private router: Router,
	  ) {}

	  get settings(): ISettings {return this.appService.settings;}
	  get ready(): boolean {return this.settingsReady && this.settings["active"] === "1" && this.langsReady && this.wordsReady && this.ufilesReady;}
	  get url(): string[] {return this.appService.url;}
    get authenticated(): boolean {return this.authService.authData !== null;}
	  get pRegisterActive(): boolean {return this.appService.pRegisterActive;}
	  set pRegisterActive(v: boolean) {this.appService.pRegisterActive = v;}
	  get pRegisteredActive(): boolean {return this.appService.pRegisteredActive;}
	  set pRegisteredActive(v: boolean) {this.appService.pRegisteredActive = v;}
	  get pLoginActive(): boolean {return this.appService.pLoginActive;}
	  set pLoginActive(v: boolean) {this.appService.pLoginActive = v;}
	  get pLogoutActive(): boolean {return this.appService.pLogoutActive;}
	  set pLogoutActive(v: boolean) {this.appService.pLogoutActive = v;}
	  get cookieModalActive(): boolean {return this.appService.cookieModalActive;}
	  set cookieModalActive(v: boolean) {this.appService.cookieModalActive = v;}

    public async ngOnInit(): Promise<void> {
		    this.initSettings();
		    this.initLangs();
		    this.initWords();
		    this.initUfiles();
		    if (!this.authenticated) {
		        this.cookieModalActive = true;
		    }
    }

	public async ngAfterViewInit(): Promise<void> {
		this.appService.win = this.winRef.nativeElement;
	}

    private async initSettings(): Promise<void> {
		try {
			this.appService.settings = await this.settingRepository.loadAll();
			this.settingsReady = true;
		} catch (err) {
			this.appService.notifyError(err);
		}
	}

    private async initLangs(): Promise<void> {
		try {
			this.appService.langs = await this.langRepository.loadAll();
			this.initLang(this.router.url.split("/")[1]);
			this.langsReady = true;
			this.router.events
				.pipe(filter(event => event instanceof NavigationStart))
				.subscribe((event: NavigationStart) => this.initLang(event.url.split("/")[1]));
		} catch (err) {
			this.appService.notifyError(err);
		}
	}

	private initLang(slug: string): void {
		if (!slug) {
			this.appService.lang = this.appService.langs[0];
			return;
		}

		const lang = this.appService.langs.find(l => l.slug === slug);

		if (!lang) {
			this.appService.lang = this.appService.langs[0];
			this.router.navigateByUrl(`/${this.appService.lang.slug}/errors/404`);
			return;
		}

		this.appService.lang = lang;
	}

    private async initWords(): Promise<void> {
		try {
			this.appService.words = await this.wordRepository.loadAll();
			this.wordsReady = true;
		} catch (err) {
			this.appService.notifyError(err);
		}
	}

	private async initUfiles(): Promise<void> {
		try {
			this.appService.ufiles = await this.ufileRepository.loadAll();
			this.ufilesReady = true;
		} catch (err) {
			this.appService.notifyError(err);
		}
	}
}
