import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { AppService } from "src/app/common/services/app.service";
import { AuthService } from "src/app/common/services/auth.service";
import { IUserLogin } from "src/app/model/dto/user.login.interface";
import { IKeyValue } from "src/app/model/keyvalue.interface";
import { PopupComponent } from "../popup.component";

@Component({
    selector: "popup-login",
    templateUrl: "popup-login.component.html",
    styleUrls: [
        "../popup.component.scss",
        "../../../styles/forms.scss",
    ],
})
export class PopupLoginComponent extends PopupComponent implements OnChanges {
    public email: string = "";
    public phone: string = "";
    public password: string = "";
    public errors: IKeyValue<string> = {};
    public errorDenied: boolean = false;
    public loading: boolean = false;

    public isEmail = false;

    constructor(
        protected appService: AppService,
        protected authService: AuthService,
        protected router: Router,
    )
    {
        super(appService);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!this.active) {
            this.errors = {};
        }
    }

    public async register(): Promise<void> {
        this.onClose();
        await this.appService.pause(500);
        this.appService.pRegisterActive = true;
    }

    public async onSubmit(): Promise<void> {
        try {
            if (!this.validate()) {
                return;
            }

            this.loading = true;
            this.errorDenied = false;
            const dto: IUserLogin = {phone: this.phone, email: this.email, password: this.password};
            const statusCode = await this.authService.login(dto);
            this.loading = false;

            if (statusCode === 200) {
                this.onClose();
                this.router.navigateByUrl(`/${this.lang.slug}/account`);
            } else if (statusCode === 401) {
                this.errorDenied = true;
            } else {
                this.onClose();
                this.appService.notifyError(this.words['errors']?.['common']?.[this.lang.slug]);
            }
        } catch (err) {
            this.appService.notifyError(err);
            this.loading = false;
        }
    }

    private validate(): boolean {
        let error = false;

        if (this.isEmail) {
            delete this.phone;
            if (!this.appService.validateEmail(this.email)) {
                this.errors["email"] = "email";
                error = true;
            } else {
                this.errors["email"] = null;
            }
        } else {
            delete this.email;
            if (!this.phone) {
                this.errors["phone"] = "required";
                error = true;
            } else {
                this.errors["phone"] = null;
            }
        }

        if (!this.password) {
            this.errors["password"] = "required";
            error = true;
        } else {
            this.errors["password"] = null;
        }

        return !error;
    }
}
