<!-- head -->
<div class="services-head">
    <div class="sh-wrap">
        <div class="sh-subwrap">
            <div class="sh-content">
                <h1>{{words['services']?.['head-title']?.[lang.slug]}}</h1>
                <h2>{{words['services']?.['text']?.[lang.slug]}}</h2>
                <a class="btn" (click)="onPromoBtnClick()"><span>{{words['home']?.['head-btn']?.[lang.slug]}}</span></a>
            </div>
        </div>
    </div>
</div>

<div class="content">
    <!-- info -->
    <section>
        <div class="services-info">
            <div class="si-item">
                <div class="sii-content">
                    <img src="/assets/images/services-info1.svg" loading="lazy" [alt]="words['services']?.['service1-title']?.[lang.slug]">
                    <div class="sii-title">{{words['services']?.['service1-title']?.[lang.slug]}}</div>
                    <div class="sii-desc">{{words['services']?.['service1-desc']?.[lang.slug]}}</div>
                </div>
            </div>
            <div class="si-item">
                <div class="sii-content">
                    <img src="/assets/images/services-info2.svg" loading="lazy" [alt]="words['services']?.['service2-title']?.[lang.slug]">
                    <div class="sii-title">{{words['services']?.['service2-title']?.[lang.slug]}}</div>
                    <div class="sii-desc">{{words['services']?.['service2-desc']?.[lang.slug]}}</div>
                </div>
            </div>
            <div class="si-item">
                <div class="sii-content">
                    <img src="/assets/images/services-info3.svg" loading="lazy" [alt]="words['services']?.['service3-title']?.[lang.slug]">
                    <div class="sii-title">{{words['services']?.['service3-title']?.[lang.slug]}}</div>
                    <div class="sii-desc">{{words['services']?.['service3-desc']?.[lang.slug]}}</div>
                </div>
            </div>
            <div class="si-item">
                <div class="sii-content">
                    <img src="/assets/images/services-info4.svg" loading="lazy" [alt]="words['services']?.['service4-title']?.[lang.slug]">
                    <div class="sii-title">{{words['services']?.['service4-title']?.[lang.slug]}}</div>
                    <div class="sii-desc">{{words['services']?.['service4-desc']?.[lang.slug]}}</div>
                </div>
            </div>
            <div class="si-item">
                <div class="sii-content">
                    <img src="/assets/images/services-info5.svg" loading="lazy" [alt]="words['services']?.['service5-title']?.[lang.slug]">
                    <div class="sii-title">{{words['services']?.['service5-title']?.[lang.slug]}}</div>
                    <div class="sii-desc">{{words['services']?.['service5-desc']?.[lang.slug]}}</div>
                </div>
            </div>
            <div class="si-item">
                <div class="sii-content">
                    <img src="/assets/images/services-info6.svg" loading="lazy" [alt]="words['services']?.['service6-title']?.[lang.slug]">
                    <div class="sii-title">{{words['services']?.['service6-title']?.[lang.slug]}}</div>
                    <div class="sii-desc">{{words['services']?.['service6-desc']?.[lang.slug]}}</div>
                </div>
            </div>
        </div>
    </section>
</div>
