<div class="dropdown">
    <div class="dropdown-toggle" [class.dropdown-toggle-active]="isDropdownOpen" (click)="toggleDropdown()">
        <a class="active">
            <span class="lang-title">{{lang.shorttitle}}</span>
        </a>
    </div>
    <ul class="dropdown-menu" [class.show]="isDropdownOpen">
        <li *ngFor="let l of langs">
            <a [routerLink]="getLangLink(l)" [class.active-dropdown]="lang.id === l.id" (click)="toggleDropdown()">
                <span class="lang-title">{{l.shorttitle}}</span>
            </a>
        </li>
    </ul>
</div>

