<!-- head -->
<div class="about-head">
    <div class="ah-wrap">
        <div class="ah-subwrap">
            <div class="ah-content">
                <h1>{{words['about']?.['head-title']?.[lang.slug]}}</h1>
                <h2>{{words['about']?.['text1']?.[lang.slug]}}</h2>
                <h2>{{words['about']?.['text2']?.[lang.slug]}}</h2>
                <h2>{{words['about']?.['text3']?.[lang.slug]}}</h2>
                <a class="btn ah-btn" (click)="onPromoBtnClick()"><span>{{words['home']?.['head-btn']?.[lang.slug]}}</span></a>
            </div>
        </div>
    </div>
</div>
