import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ILang } from "src/app/model/entities/lang.interface";
import { AppService } from "../../services/app.service";

@Component({
    selector: "input-switch",
    templateUrl: "input-switch.component.html",
    styleUrls: ["input-switch.component.scss"],
})
export class InputSwitchComponent {
    @Input() label: string;
    @Input() value: boolean;
    @Output() valueChange: EventEmitter<boolean> = new EventEmitter();

    constructor(private appService: AppService) {}

    get words(): any {return this.appService.words;}
    get lang(): ILang {return this.appService.lang;}

    public onToggle(): void {
        this.value = !this.value
        this.valueChange.emit(this.value)
    }
}
