<!-- head -->
<div class="deposit-head">
    <div class="dh-wrap">
        <div class="dh-subwrap">
            <div class="dh-content">
                <h1>{{words['deposit']?.['head-title']?.[lang.slug]}}</h1>
                <h2>{{words['deposit']?.['text1']?.[lang.slug]}}</h2>
                <h2>{{words['deposit']?.['text2']?.[lang.slug]}}</h2>
                <a class="btn dh-btn" (click)="onPromoBtnClick()"><span>{{words['home']?.['head-btn']?.[lang.slug]}}</span></a>
            </div>
        </div>
    </div>
</div>

<div class="content">
    <!-- info -->
    <h2 name="deposit" class="deposit-main-title">{{words['deposit']?.['info-title']?.[lang.slug]}}</h2>
    <section>
        <div class="deposit-info">
            <div class="di-item">
                <div class="dii-content">
                    <div class="dii-title">{{words['deposit']?.['card1']?.[lang.slug]}}</div>
                </div>
            </div>
            <div class="di-item">
                <div class="dii-content">
                    <div class="dii-title">{{words['deposit']?.['card2']?.[lang.slug]}}</div>
                </div>
            </div>
            <div class="di-item">
                <div class="dii-content">
                    <div class="dii-title">{{words['deposit']?.['card3']?.[lang.slug]}}</div>
                </div>
            </div>
            <div class="di-item">
                <div class="dii-content">
                    <div class="dii-title">{{words['deposit']?.['card4']?.[lang.slug]}}</div>
                </div>
            </div>
            <div class="di-item">
                <div class="dii-content">
                    <div class="dii-title">{{words['deposit']?.['card5']?.[lang.slug]}}</div>
                </div>
            </div>
            <div class="di-item">
                <div class="dii-content">
                    <div class="dii-title">{{words['deposit']?.['card6']?.[lang.slug]}}</div>
                </div>
            </div>
        </div>
    </section>
</div>
