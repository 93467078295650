import { Component, OnChanges, SimpleChanges } from "@angular/core";
import { AppService } from "src/app/common/services/app.service";
import { AuthService } from "src/app/common/services/auth.service";
import { IUserRegister } from "src/app/model/dto/user.register.interface";
import { IKeyValue } from "src/app/model/keyvalue.interface";
import { PopupComponent } from "../popup.component";

@Component({
    selector: "popup-register",
    templateUrl: "popup-register.component.html",
    styleUrls: [
        "../popup.component.scss",
        "../../../styles/forms.scss",
    ],
})
export class PopupRegisterComponent extends PopupComponent implements OnChanges {
    public lastname: string = "";
    public firstname: string = "";
    public middlename: string = "";
    public addr: string = "";
    public phone: string = "";
    public email: string = "";
    public password: string = "";
    public password2: string = "";
    public errors: IKeyValue<string> = {};
    public errorExists: boolean = false;
    public loading: boolean = false;

    public isPhone = false;

    constructor(
        protected appService: AppService,
        protected authService: AuthService,
    )
    {
        super(appService);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!this.active) {
            this.errors = {};
        }
    }

    public async login(): Promise<void> {
        this.onClose();
        await this.appService.pause(500);
        this.appService.pLoginActive = true;
    }

    public removeNonLatin(param: string): void {
        this[param] = this[param].replace(/[^a-z0-9\s-]/gi, ''); // latin, digits, space, dash
    }

    public removeNonDigit(param: string): void {
        this[param] = this[param].replace(/\D/g, '');
    }

    public async onSubmit(): Promise<void> {
        try {
            if (!this.validate()) {
                return;
            }

            this.loading = true;
            this.errorExists = false;
            const dto: IUserRegister = {
                lang_id: this.lang.id,
                email: this.isPhone ? this.email : "",
                password: this.password,
                lastname: this.lastname,
                firstname: this.firstname,
                middlename: this.middlename,
                addr: this.addr,
                phone: this.phone,
            };
            const statusCode = await this.authService.register(dto);
            this.loading = false;

            if (statusCode === 201) {
                this.onClose();
                await this.appService.pause(500);
                this.appService.pRegisteredActive = true;
            } else if (statusCode === 409) {
                this.errorExists = true;
            } else {
                this.onClose();
                this.appService.notifyError(this.words['errors']?.['common']?.[this.lang.slug]);
            }
        } catch (err) {
            this.appService.notifyError(err);
            this.loading = false;
        }
    }

    private validate(): boolean {
        let error = false;

        if (!this.lastname) {
            this.errors["lastname"] = "required";
            error = true;
        } else {
            this.errors["lastname"] = null;
        }

        if (!this.firstname) {
            this.errors["firstname"] = "required";
            error = true;
        } else {
            this.errors["firstname"] = null;
        }

        if (!this.addr) {
            this.errors["addr"] = "required";
            error = true;
        } else {
            this.errors["addr"] = null;
        }

        if (!this.phone) {
            this.errors["phone"] = "required";
            error = true;
        } else {
            this.errors["phone"] = null;
        }

        if (!this.password) {
            this.errors["password"] = "required";
            error = true;
        } else {
            this.errors["password"] = null;
        }

        if (this.password2 !== this.password) {
            this.errors["password2"] = "password2";
            error = true;
        } else {
            this.errors["password2"] = null;
        }

        return !error;
    }
}
