import { Injectable } from "@angular/core";
import { filter, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { cfg } from "src/app/config";
import { IResponse } from "src/app/model/dto/response.interface";
import { ISettings } from "src/app/model/entities/settings.interface";
import { IWords } from "src/app/model/entities/words.interface";
import { IUfiles } from "src/app/model/entities/ufile.interface";
import { ILang } from "src/app/model/entities/lang.interface";
import { IPage } from "src/app/model/entities/page.interface";
import { IUserAuthData } from "src/app/model/dto/user.authdata.interface";
import { IUserLogin } from "src/app/model/dto/user.login.interface";
import { Router } from "@angular/router";
import { IUserRegister } from "src/app/model/dto/user.register.interface";
import { ICard } from "src/app/model/entities/card.interface";
import { IGetList } from "src/app/model/dto/getlist.interface";
import { ITransaction } from "src/app/model/entities/transaction.interface";
import { ITransactionCreate } from "src/app/model/dto/transaction.create.interface";
import { IUser } from "src/app/model/entities/user.interface";
import { INotification } from "src/app/model/entities/notification.interface";
import { IPaysystem } from "src/app/model/entities/paysystem.interface";
import { ICreditCreate } from "src/app/model/dto/credit.create.interface";

@Injectable()
export class DataService {
    public authData: IUserAuthData = null;

    constructor (
        private http: HttpClient,
        private router: Router,
    ) {}

    public settingsAll(): Observable<IResponse<ISettings>> {return this.sendRequest("settings/all");}

    public langsAll(): Observable<IResponse<ILang[]>> {return this.sendRequest("langs/all");}

    public wordsAll(): Observable<IResponse<IWords>> {return this.sendRequest(`words/all`);}

    public ufilesAll(): Observable<IResponse<IUfiles>> {return this.sendRequest("ufiles/all");}

    public pagesOne(slug: string): Observable<IResponse<IPage>> {return this.sendRequest(`pages/one/${slug}`);}

    public usersLogin(dto: IUserLogin): Observable<IResponse<IUserAuthData>> {return this.sendRequest("users/login", dto);}
    public usersRegister(dto: IUserRegister): Observable<IResponse<void>> {return this.sendRequest("users/register", dto);}
    public usersOne(id: number): Observable<IResponse<IUser>> {return this.sendRequest(`users/one/${id}`);}

    public cardsAll(dto: IGetList): Observable<IResponse<ICard[]>> {return this.sendRequest(`cards/all`, dto);}

    public transactionsChunk(dto: IGetList): Observable<IResponse<ITransaction[]>> {return this.sendRequest("transactions/chunk", dto);}
    public transactionsCreate(dto: ITransactionCreate): Observable<IResponse<void>> {return this.sendRequest("transactions/create", dto);}

    public notificationsChunk(dto: IGetList): Observable<IResponse<INotification[]>> {return this.sendRequest("notifications/chunk", dto);}

    public paysystemsAll(): Observable<IResponse<IPaysystem[]>> {return this.sendRequest("paysystems/all");}

    public creditsCreate(dto: ICreditCreate): Observable<IResponse<void>> {return this.sendRequest("credits/create", dto);}

    private sendRequest (url: string, body: any = null): Observable<any> {
        const headersContent = {};
        this.authData?.token && (headersContent["token"] = this.authData.token);
        const headers = new HttpHeaders(headersContent);
        return this.http
            .post(`${cfg.apiUrl}/${url}`, body, {headers})
            .pipe(filter(res => this.processResponse(res)));
    }

    private processResponse(res: any): boolean {
        if (res.statusCode === 403) {
            console.log(res.error);
            this.router.navigateByUrl(`/ru/account/logout`);
            return false;
        }

        return true;
    }
}
