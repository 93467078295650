<!-- <div class="content"> -->
<!--     <div class="multi-container"> -->
<!--         <div class="container" *ngFor="let card of cards"> -->
<!--             <card-terminal [card]="card"></card-terminal> -->
<!--         </div> -->
<!--     </div> -->
<!-- </div> -->
<div class="single-container">
    <cards-terminal *ngIf="isCardsReady" [cards]="cards"></cards-terminal>
</div>
