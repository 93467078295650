import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { AppService } from "src/app/common/services/app.service";
import { TransactionRepository } from "src/app/common/services/repositories/transaction.repository";
import { ITransactionCreate } from "src/app/model/dto/transaction.create.interface";
import { ICard } from "src/app/model/entities/card.interface";
import { IKeyValue } from "src/app/model/keyvalue.interface";
import { PopupComponent } from "../../popup.component";

@Component({
    selector: "popup-send-crypto",
    templateUrl: "popup-send-crypto.component.html",
    styleUrls: [
        "../../popup.component.scss",
        "../../../../styles/forms.scss",
    ],
})
export class PopupSendCryptoComponent extends PopupComponent implements OnChanges {
    @Input() card: ICard;
    @Output() sent: EventEmitter<void> = new EventEmitter();
    public ca_no: string = "";
    public value: number = null;
    public errors: IKeyValue<string> = {};
    public loading: boolean = false;

    constructor(
        protected appService: AppService,
        protected transactionRepository: TransactionRepository,
    )
    {
        super(appService);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!this.active) {
            this.errors = {};
        }
    }

    public removeNonWallet(param: string): void {
        this[param] = this[param].replace(/[^a-z0-9-]/gi, ''); // latin, digits, dash
    }

    public async onSubmit(): Promise<void> {
        try {
            if (!this.validate()) {
                return;
            }

            this.loading = true;
            const dto: ITransactionCreate = {card_id: this.card.id, value: -this.value, ca_no: this.ca_no};
            const statusCode = await this.transactionRepository.create(dto);
            this.loading = false;
            this.onClose();
            this.reset();

            if (statusCode === 201) {
                this.sent.emit();
            } else {
                this.appService.notifyError(this.words['errors']?.['common']?.[this.lang.slug]);
            }
        } catch (err) {
            this.appService.notifyError(err);
            this.loading = false;
        }
    }

    private reset(): void {
        this.ca_no = "";
        this.value = null;
    }

    private validate(): boolean {
        let error = false;

        if (!this.ca_no) {
            this.errors["ca_no"] = "required";
            error = true;
        } else {
            this.errors["ca_no"] = null;
        }

        if (typeof this.value !== "number" || this.value < 0) {
            this.errors["value"] = "amount";
            error = true;
        } else if (this.value > this.card.balance) {
            this.errors["value"] = "insufficient";
            error = true;
        } else {
            this.errors["value"] = null;
        }

        return !error;
    }
}
