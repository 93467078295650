<div [class]="'card ' + customClass">
    <div class="card-content">
        <div class="card-details">
            <div class="card-title">{{card.type.subtype === 'bank' ? card.no : card.type.name}}</div>
            <div class="card-network" *ngIf="card.type.subtype === 'crypto'">{{card.type.network.name}}</div>
        </div>
        <div class="card-bottom">
            <ng-container *ngIf="card.type.subtype === 'bank'">
                <div class="card-exp">{{card.exp_month}} / {{card.exp_year}}</div>
                <div class="card-cvv" (click)="toggleCvv()">{{showCvv ? card.cvv : 'CVV'}}</div>
            </ng-container>
        </div>
        <div class="card-top">
            <div class="card-logo"><img *ngIf="card.type.img" src="/images/cardtypes/{{card.type.img}}" [alt]="card.type.name"></div>
            <div class="card-balance"><span *ngIf="card.type.currency.sign">{{card.type.currency.sign}}&nbsp;</span>{{card.balance}}</div>
        </div>
    </div>
</div>
