import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { ComponentsModule } from "src/app/common/components/components.module";
import { HomePage } from "./pages/home.page";
import { GoogleMapsModule } from '@angular/google-maps';

@NgModule({
    imports: [
		  CommonModule,
		  RouterModule,
		  ComponentsModule,
      GoogleMapsModule,
	  ],
	  declarations: [
		  HomePage,
	  ],
})
export class HomeModule {}
