import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './common/services/guards/auth.guard';
import { AboutPage } from './pages/about/pages/about.page';
import { AccountModule } from './pages/account/account.module';
import { CryptoPage } from './pages/crypto/pages/crypto.page';
import { DepositPage } from './pages/deposit/pages/deposit.page';
import { ErrorsModule } from './pages/errors/errors.module';
import { HomePage } from './pages/home/pages/home.page';
import { PartnersPage } from './pages/partners/pages/partners.page';
import { ServicesPage } from './pages/services/pages/services.page';
import { StaticModule } from './pages/static/static.module';

const routes: Routes = [
    {path: "", component: HomePage, data: {mark: "home"}}, // mark for reuse
    {path: "ru", pathMatch: "full", redirectTo: "/"},
    {path: ":lang", component: HomePage, data: {mark: "home"}},  // mark for reuse
    {path: ":lang/errors", loadChildren: () => ErrorsModule},
    {path: ":lang/account", loadChildren: () => AccountModule, canActivate: [AuthGuard]},
    {path: ":lang/about", component: AboutPage},
    {path: ":lang/partners", component: PartnersPage},
    {path: ":lang/services", component: ServicesPage},
    {path: ":lang/crypto", component: CryptoPage},
    {path: ":lang/deposit", component: DepositPage},
    {path: ":lang/:page", loadChildren: () => StaticModule},
    {path: "**", redirectTo: "/ru/errors/404"}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {initialNavigation: 'enabledBlocking'})],
    exports: [RouterModule],
})
export class AppRoutingModule { }
