import { Component, Input } from "@angular/core";
import { AppService } from "src/app/common/services/app.service";
import { ILang } from "src/app/model/entities/lang.interface";

@Component({
    selector: "cookie-warning",
    templateUrl: "cookie-warning.component.html",
    styleUrls: ["cookie-warning.component.scss" ]
})
export class CookieWarningComponent {
    @Input() public active: boolean = false;
    public loading: boolean = false;

    constructor(
        protected appService: AppService,
    ) {}

    get words(): any {return this.appService.words;}
    get lang(): ILang {return this.appService.lang;}
    get text(): string {return this.words["common"]?.["login"]?.[this.lang.slug];}
	  set cookieModalActive(v: boolean) {this.appService.cookieModalActive = v;}

    public onClose(): void {
        this.active = false;
    }
}
