<!-- head -->
<div class="home-head">
    <div class="hh-wrap">
        <div class="hh-subwrap">
            <div class="hh-content">
                <h1>{{words['home']?.['head-title']?.[lang.slug]}}</h1>
                <h2>{{words['home']?.['head-subtitle']?.[lang.slug]}}</h2>
                <a class="btn" (click)="onPromoBtnClick()"><span>{{words['home']?.['head-btn']?.[lang.slug]}}</span></a>
            </div>
        </div>
    </div>
</div>
<div class="content">
    <!-- about -->
    <h2 name="about">{{words['home']?.['about-title']?.[lang.slug]}}</h2>
    <section>
        <div class="home-about">
            <div class="ha-item">
                <div class="ha-imgwrap"><img src="/assets/images/home-about1.png" loading="lazy" alt="about us"></div>
                <div class="ha-title">{{words['home']?.['about1-title']?.[lang.slug]}}</div>
                <div class="ha-txt" [innerHTML]="words['home']?.['about1-txt']?.[lang.slug]"></div>
            </div>
            <div class="ha-item">
                <div class="ha-imgwrap"><img src="/assets/images/home-about2.png" loading="lazy" alt="about us"></div>
                <div class="ha-title">{{words['home']?.['about2-title']?.[lang.slug]}}</div>
                <div class="ha-txt" [innerHTML]="words['home']?.['about2-txt']?.[lang.slug]"></div>
            </div>
            <div class="ha-item">
                <div class="ha-imgwrap"><img src="/assets/images/home-about3.png" loading="lazy" alt="about us"></div>
                <div class="ha-title">{{words['home']?.['about3-title']?.[lang.slug]}}</div>
                <div class="ha-txt" [innerHTML]="words['home']?.['about3-txt']?.[lang.slug]"></div>
            </div>
        </div>
        <div class="home-about-bottom">
            <div class="ha-txt" [innerHTML]="words['home']?.['about1-txt']?.[lang.slug]"></div>
            <div class="ha-txt" [innerHTML]="words['home']?.['about2-txt']?.[lang.slug]"></div>
            <div class="ha-txt" [innerHTML]="words['home']?.['about3-txt']?.[lang.slug]"></div>
        </div>
    </section>
</div>
<!-- help -->
<section class="home-help-section">
    <div class="content">
        <div class="home-help">
            <div class="home-help-left">
                <h2 class="help-title" name="help">{{words['home']?.['help-title']?.[lang.slug]}}</h2>
                <div class="help-subtitle">{{words['home']?.['help-subtitle']?.[lang.slug]}}</div>
            </div>
            <img class="curve-arrow" src="/assets/images/curve-arrow.svg" />
            <div class="help-icons">
                <a [href]="words['home']?.['whatsapp']?.[lang.slug]" target="_blank"><img src="/assets/images/help-whatsapp.svg" /></a>
                <a [href]="words['home']?.['telegram']?.[lang.slug]" target="_blank"><img src="/assets/images/help-telegram.svg" /></a>
            </div>
        </div>
    </div>
</section>
<!-- faq -->
<section class="faq-section">
    <div class="content">
        <div class="faq">
            <div class="faq-left">
                <h2 class="faq-title" name="help">{{words['home']?.['faq-title']?.[lang.slug]}}</h2>
                <div class="f-content">
                    <div class="f-block">
                        <div class="f-question">{{words['home']?.['faq-question1']?.[lang.slug]}}</div>
                        <div class="f-answer">{{words['home']?.['faq-answer1']?.[lang.slug]}}</div>
                    </div>
                    <div class="f-block">
                        <div class="f-question">{{words['home']?.['faq-question2']?.[lang.slug]}}</div>
                        <div class="f-answer">{{words['home']?.['faq-answer2']?.[lang.slug]}}</div>
                    </div>
                    <div class="f-block">
                        <div class="f-question">{{words['home']?.['faq-question3']?.[lang.slug]}}</div>
                        <div class="f-answer">{{words['home']?.['faq-answer3']?.[lang.slug]}}</div>
                    </div>
                    <div class="f-block">
                        <div class="f-question">{{words['home']?.['faq-question4']?.[lang.slug]}}</div>
                        <div class="f-answer">{{words['home']?.['faq-answer4']?.[lang.slug]}}</div>
                    </div>
                </div>
            </div>
            <div class="faq-right">
                <google-map
                    class="f-map-desktop"
                    height="435px"
                    width="418px"
                    [options]="mapOptions"
                >
                    <map-marker [position]="marker.position"></map-marker>
                </google-map>
                <google-map
                    class="f-map-mobile"
                    height="300px"
                    width="300px"
                >
                    <map-marker [position]="marker.position"></map-marker>
                </google-map>
                <img class="f-logo" src="/assets/images/logo-2.svg"/>
            </div>
        </div>
    </div>
</section>
