<!-- head -->
<div class="crypto-head">
    <div class="ch-wrap">
        <div class="ch-subwrap">
            <div class="ch-content">
                <h1>{{words['crypto']?.['head-title']?.[lang.slug]}}</h1>
                <h2>{{words['crypto']?.['subtitle']?.[lang.slug]}}</h2>
                <a class="btn" (click)="onPromoBtnClick()"><span>{{words['home']?.['head-btn']?.[lang.slug]}}</span></a>
            </div>
        </div>
    </div>
</div>
