import { Component, EventEmitter, Input, Output } from "@angular/core";
import { TPeriod } from "./period.type";
import { AppService } from "../../services/app.service";
import { IWords } from "src/app/model/entities/words.interface";
import { ILang } from "src/app/model/entities/lang.interface";

@Component({
    selector: "the-period",
    templateUrl: "period.component.html",
    styleUrls: ["period.component.scss"],
})
export class PeriodComponent {
    @Input() public value: TPeriod;
    @Output() private valueChange: EventEmitter<TPeriod> = new EventEmitter();

    constructor(protected appService: AppService) {}

    get words(): IWords {return this.appService.words;}
    get lang(): ILang {return this.appService.lang;}

    public onSelect(v: TPeriod): void {
        this.valueChange.emit(v);
    }
}
