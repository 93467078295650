<div class="mm" [class.active]="active">
    <div class="mm-content">
        <nav class="mm-menu">
            <ul>
                <li><a [class.active]="!url[2]" routerLink="/{{lang.slug}}">{{words['header']?.['main']?.[lang.slug]}}</a></li>
                <li><a [class.active]="url[2] === 'about' && !url[3]" routerLink="/{{lang.slug}}/about">{{words['header']?.['about']?.[lang.slug]}}</a></li>
                <li><a [class.active]="url[2] === 'partners' && !url[3]" routerLink="/{{lang.slug}}/partners">{{words['header']?.['partners']?.[lang.slug]}}</a></li>
                <li><a [class.active]="url[2] === 'services' && !url[3]" routerLink="/{{lang.slug}}/services">{{words['header']?.['services']?.[lang.slug]}}</a></li>
                <li><a [class.active]="url[2] === 'deposit' && !url[3]" routerLink="/{{lang.slug}}/deposit">{{words['header']?.['deposit']?.[lang.slug]}}</a></li>
                <li><a [class.active]="url[2] === 'crypto' && !url[3]" routerLink="/{{lang.slug}}/crypto">{{words['header']?.['crypto-transfer']?.[lang.slug]}}</a></li>
            </ul>
        </nav>
        <div class="mm-bottom">
            <a href="#" class="btn mm-btn">
                <span class="ha-btn-text">{{words['header']?.['support']?.[lang.slug]}}</span>
            </a>
            <lang-dropdown></lang-dropdown>
        </div>
    </div>
</div>
